.signin,
.signup {
	&__title {
		position: relative;

		canvas {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(0, -50%);
			pointer-events: none;
			z-index: 1000;
		}
	}

	&__container {
		display: inline-flex;
		flex-direction: column;
		gap: 0.5rem;

		&__required-fields {
			text-align: right;
		}
	}

	&__credentials {
		display: flex;
		flex-direction: column;

		> * + * {
			margin-top: 1.618rem;
		}

		.input {
			width: 100%;
		}
	}

	&__warning {
		border: 1px solid rgba($yellow, 0.1);
		border-radius: 6px;
		background: rgba($yellow, 0.02);

		padding: 1rem;
		display: flex;
		flex-direction: column;
		color: $yellow;

		&__title {
			display: flex;
			margin-bottom: 0.5rem;
			align-items: center;
			font-weight: $boldWeight;
			font-size: 0.9rem;

			svg {
				margin-right: 0.25rem;
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		.button {
			align-self: flex-start;
			background: lighten($yellow, 40%);
			color: $yellow;

			&.promise-button {
				&.promise-button--waiting {
					&:before {
						background: darken(lighten($yellow, 40%), 5%);
					}
					&:after {
						background: $yellow;
					}
				}
			}
		}
	}

	&__register {
		font-weight: 400;
		text-align: center;
		a {
			margin-left: 0.5rem;
		}
	}
}

.app--desktop .signin,
.app--desktop .signup {
	&__container {
		width: 360px;
	}
}
.app--mobile .signin,
.app--mobile .signup {
	&__container {
		width: 100%;
	}
}

.oauth {
	min-height: 45vh;
	line-height: 45vh;

	&__progress {
		line-height: 1.2;
		display: inline-block;
		vertical-align: middle;

		> * {
			display: inline-block;
			vertical-align: middle;
		}

		h2 {
			margin: 0 0 0 1rem;
		}
	}

	&__error {
		line-height: 1.2;
	}
}
