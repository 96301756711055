@import '~@faceyourmanga/fym-shared/src/sass/variables.scss';

$color1: #293282;
$color2: #682581;
$color3: mix($color1, $color2, 50%);
$third: #47ceba;

$secondary--light: lighten($secondary, 30%);
$green: #3ebfab;
$red: #b80000;
$yellow: #e0a100;

$gray: desaturate(darken($background, 10%), 40%);

$base-color: desaturate(darken($background, 80%), 50%);
$veryBoldWeight: 600;
$boldWeight: 400;
$appContainerSize: 1100px;

$gradient-fallback: $color1;
$gradient: linear-gradient(90deg, $color1 0%, $color2 100%);

@mixin enterAnim($animName: enter, $animDur: 500ms, $animDelay: 60ms, $size: 12, $ease: $ease1, $rows: 2) {
	will-change: opacity, transform;
	animation: $animName $animDur $ease1 both;
	$cols: $size / $rows;
	@for $i from 1 through $size {
		&:nth-child(#{$i}) {
			animation-delay: $i * $animDelay - floor(($i - 1) / $cols) * ($animDelay * $cols * 0.75);
		}
	}
}
