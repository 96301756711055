@keyframes openBackground {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes openModal {
	from {
		opacity: 0;
		transform: scale(0.98);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;

	&--desktop {
		.modal {
			&__content__wrapper {
				padding: 2rem;
			}
		}
	}
	&--mobile {
		.modal {
			&__content__wrapper {
				padding: 1rem;
			}
		}
	}

	&__background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		animation: openBackground 0.1s ease-out both;
		background: rgba(darken($color1, 10%), 0.2);
	}

	&__content {
		position: relative;
		z-index: 1000;
		max-width: 90vw;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
		animation: openModal 0.1s ease-out both;
		border-radius: 1rem;
		background: #fff;

		&__wrapper {
			width: 100%;
			overflow: auto;
			max-height: 90vh;
		}

		&__close-icon {
			position: absolute;
			top: 1rem;
			right: 1rem;
			cursor: pointer;

			& + * {
				margin-top: 1rem;
			}

			svg {
				width: 2rem;
				height: 2rem;
			}
		}
	}
}
