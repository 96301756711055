.editor {
}

.app--mobile .editor {
	overflow: hidden;
	height: 100%;

	.app__content {
		padding: 0;
		height: 100%;
	}

	&__wrapper {
		height: 100%;

		&__container {
			height: calc(100% - 80px);
		}
		&__adv {
			height: 80px;

			.ads-container {
				height: 100%;
				width: 100%;
				.ads--horizontal {
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	.fym-editor {
		height: 100%;
	}
}

.app--desktop .editor {
	.ads-container {
		margin: 2rem auto 2rem;

		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}

	&__wrapper {
		width: $appContainerSize;
		height: 100%;

		&__container {
			display: inline-block;
			vertical-align: middle;
			width: 50rem;
		}

		&__ads {
			display: inline-block;
			vertical-align: middle;
			height: 35.875rem;
			width: calc(#{$appContainerSize} - 51rem);

			.ads-container {
				height: 100%;
				width: 100%;
				margin: 0 0 0 2rem;

				.ads--vertical {
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}
