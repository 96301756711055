.hashtag {
	// background: darken($background, 5%);
	// padding: 0.2rem 1rem 0.2rem 0.8rem;
	// border-radius: 1rem;
	// color: $color1;
	// transition: opacity 0.1s $ease1;
	// font-weight: 400;
	// font-size: 0.9rem;
	background: #d9d9ef;
	padding: 0.2rem 1rem 0.2rem 0.8rem;
	border-radius: 1rem;
	color: #4c517a;
	transition: opacity 0.1s $ease1;
	font-weight: 400;
	font-size: 0.9rem;

	span {
		display: inline-block;
		vertical-align: middle;
		font-size: 1.1rem;
		margin-right: 0.1rem;
	}
	svg {
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
	}
	&:hover {
		opacity: 0.8;
	}
}
