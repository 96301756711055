@keyframes avatar-paginator-enter {
	from {
		opacity: 0;
		transform: scale(0.9);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.avatar-paginator {
	position: relative;

	&__list {
		position: relative;
		font-size: 0;

		> * {
			padding: 0.3rem;
			@include enterAnim(avatar-paginator-enter, 500ms, 60ms, 10, $ease1);
		}
	}

	&__actions {
		display: flex;
		width: 100%;
		margin: 0.5rem 0 0 0;
		justify-content: center;

		&__arrow {
			cursor: pointer;
			margin: 0 0.5rem;

			&--disabled {
				opacity: 0.2;
				pointer-events: none;
			}

			svg {
				width: 3rem;
				height: 3rem;
				transition: transform 0.1s $ease1;
			}
		}
	}
}

.app--desktop .avatar-paginator {
	&__list {
		> * {
			width: 16.35%;
			height: auto;
			margin: 0.1rem;
		}
	}

	&__actions {
		&__arrow {
			cursor: pointer;

			&:hover svg {
				transform: scale(1.2);
			}
		}
	}
}

.app--desktop .avatar-paginator {
	&__list {
		white-space: nowrap;
	}
}

.app--mobile .avatar-paginator {
	&__list {
		> * {
			width: 49%;
			height: auto;
			margin: 0.5%;
		}
	}
}
