$animDur: 1000ms;

.appear {
	transform-origin: center center;
	will-change: opacity, transform;
	transition: all $animDur;

	&--visible {
		opacity: 1;
		transform: translateY(0);
	}

	&--hidden {
		opacity: 0;
		transform: translateY(-100%);
	}
}
