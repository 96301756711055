.ads {
	// background: $background;
	text-align: center;

	&--vertical {
	}

	&--horizontal {
		width: 100%;
		max-width: 1100px;
		min-height: 20vh;
		margin: 0 auto;
	}

	&--square {
	}
}

.ads-container {
}
