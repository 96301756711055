.app {
	$transition-dutaration: 0.2s;

	&__route {
		background: #fff;
		will-change: opacity;

		&-enter {
			min-height: 50vh;
		}

		&-enter,
		&-exit {
			transition: opacity $transition-dutaration $ease1;
		}

		&-enter {
			transition-delay: $transition-dutaration;
		}

		&-enter,
		&-exit.app__route-exit-active {
			opacity: 0;
		}

		&-exit,
		&-enter.app__route-enter-active {
			opacity: 1;
		}

		&-exit {
			position: absolute;
			left: 0;
			width: 100%;

			z-index: 1;
		}
	}

	&--mobile {
		.app__route {
			&-exit {
				top: 3rem;
			}
		}
	}
	&--desktop {
		.app__route {
			&-exit {
				top: 5.618rem;
			}
		}
	}
}
