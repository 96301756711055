.personal-avatar {
	input,
	.textarea {
		font-size: 1.1rem;
	}

	&__edit-message {
		text-align: center;
		margin: 0.5rem 0;
	}

	.ads-container {
		margin: 2rem auto 2rem;
		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}

	&__back {
		margin: 2rem 0;
		font-size: 2rem;
		line-height: 2rem;
		a > * {
			display: inline-block;
			vertical-align: middle;
		}

		span {
			margin-left: 0.5rem;
		}
	}

	&__image-wrapper {
		text-align: center;
		display: inline-block;
		vertical-align: top;
		width: 300px;

		&__image {
			width: 100%;
			position: relative;
			margin-bottom: 0.5rem;

			.button {
				background: #fff !important;
				color: #4a4a4a;
				border: 1px solid #b4b4b4;
			}

			&::before {
				display: block;
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&__button {
				position: absolute;
				bottom: 1rem;
				left: 1rem;
			}

			img {
				width: 100%;
			}
		}

		&__delete {
			cursor: pointer;
			font-weight: 400;
			line-height: 1rem;
			margin: 0.75rem auto 0;

			.icon {
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.25rem;
				position: relative;
				top: -1px;
			}
		}
	}

	&__meta {
		width: 100%;
		max-width: 500px;

		&__row {
			margin-top: 1.618rem;

			&__margined {
				display: block;
			}
			&__margined + &__margined {
				margin-top: 0.5rem;
			}

			> .input,
			> .textarea {
				width: 100%;
			}
		}

		&__buttons {
			margin: 2rem 0;
			> * {
				display: inline-block;
				vertical-align: middle;
				margin: 0 2rem 0 0;
			}

			&__visibility {
				cursor: pointer;
				.icon {
					display: inline-block;
					vertical-align: middle;
					position: relative;
					top: -1px;
					margin-left: 0.5rem;
				}
			}
		}

		.textarea {
			min-width: 80%;
			min-height: 4rem;
		}
	}

	&__content.app__content {
		padding-bottom: 2rem;
		border-bottom: 1px solid $gray;
	}

	&__createds {
		margin: 3rem auto 1rem;
	}
}

.app--desktop .personal-avatar {
	&__image,
	&__meta {
		display: inline-block;
		vertical-align: top;
	}

	&__meta {
		margin-left: 2rem;
	}

	&__image {
		width: 36%;
	}
}

.app--mobile .personal-avatar {
	&__image-wrapper {
		width: 100%;
	}

	&__meta {
		margin-top: 1rem;
	}

	.addToCartButton {
		width: 100%;
		text-align: center;
		margin: 3rem auto 0;
	}
}
