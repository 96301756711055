@keyframes confirm-focus-animation {
	from {
		transform: translateX(-50%) translateY(-50%) scale(1.05);
		box-shadow: 0 1rem 1rem rgba(20, 20, 20, 0);
	}
	to {
		transform: translateX(-50%) translateY(-50%) scale(1);
		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
	}
}

#confirm-root,
#alert-root {
	background-color: rgba(#000, 0);
	transition: background-color 0.3s $ease1;
	user-select: none;

	&.open {
		position: fixed;
		z-index: 100000000;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(#000, 0.4);

		.confirm,
		.alert {
			width: 90vw;
			max-width: 400px;
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
	}

	.confirm,
	.alert {
		position: fixed;
		z-index: 1000000000;
		top: 50%;
		left: 50%;
		background: $background;
		border-radius: 20px;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
		padding: 3rem 2rem 2rem 2rem;
		transition: transform 0.3s $ease1;
		transform: translateX(-50%) translateY(-50%) scale(0.6);

		&.confirm--focus {
			animation: confirm-focus-animation 0.3s $ease1 both;
		}

		&__label {
			font-size: 1.3rem;
			margin-bottom: 1.618rem;
			text-align: center;
			font-weight: 400;
		}

		&__input {
			width: 20rem;
			line-height: 1;
			background: #111;
			border: none;
			margin-right: 1rem;
			padding: 1rem;
		}

		&__confirm_buttons {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-end;
		}
	}

	.button {
		font-weight: 900;
		padding: 0 1.3rem;

		&:hover {
			transform: scale(1.05);

			&.button--shadowed:not(.button--small) {
				box-shadow: 0rem 0.2rem 0.1rem rgba(#000, 0.1);
			}
		}

		&:active {
			transform: scale(0.98) !important;
			opacity: 0.8;
		}
	}
}
