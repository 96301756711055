@keyframes dashboard__paginator_enter {
	from {
		opacity: 0;
		transform: scaleY(0.9);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.dashboard__paginator {
	&__list {
		> * {
			transform-origin: center top;
			@include enterAnim(dashboard__paginator_enter, 500ms, 60ms, 10, $ease1);
		}
	}

	&__actions {
		display: flex;
		width: 100%;
		margin: 0.5rem 0 0 0;
		justify-content: center;
		position: relative;

		&__pages {
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
		}

		&__arrow {
			cursor: pointer;
			margin: 0 0.5rem;

			&--disabled {
				opacity: 0.2;
				pointer-events: none;
			}

			svg {
				width: 3rem;
				height: 3rem;
			}
		}
	}
}
