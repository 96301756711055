.app__header {
	position: fixed;
	width: 100%;
	background: $gradient-fallback;
	background: $gradient;

	.app__content {
		display: flex;
		color: #fff;

		justify-content: space-between;
		align-items: center;
	}

	z-index: 100;

	&__logo {
		font-size: 0;
		overflow: hidden;

		&__image {
			height: 100%;
			transform-origin: left bottom;
			animation: logoAnimation 0.5s 0.3s $ease1 both;
		}
	}

	&__nav {
		display: flex;
		gap: 1rem;
		align-items: center;

		&__login {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

.app--mobile .app__header {
	top: 0;
	left: 0;
	height: 4rem;
	overflow: hidden;
	transition: all 0.3s $ease1;
	padding: 0 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&__logo {
		display: inline-block;
		height: 1.2rem;
		transition: all 0.3s $ease1;
	}

	svg {
		position: absolute;
		right: 1rem;
	}

	&--minimized {
		.app__header__logo {
			height: 1rem;
		}

		height: 3rem;
		// box-shadow: 0 2px 1px rgba(#000, 0.1);
	}
}

.app--desktop .app__header {
	top: 0;
	height: 5.618rem;
	padding: 2rem 0 1rem;

	.app__content {
		height: 100%;
	}

	&__logo {
		height: 1.618rem;
	}
}

@keyframes logoAnimation {
	0% {
		transform: translate(0, 160%) rotateZ(6deg);
	}
	100% {
		transform: translate(0, 0%) rotateZ(0deg);
	}
}
