.app--mobile .tab {
	&__list {
		margin: 0;
		white-space: nowrap;
		text-align: center;
		font-size: 0.9rem;

		&__item {
			& + .tab__list__item {
				margin-left: 0.25rem;
			}
			padding: 0.5rem 0.75rem;
		}
	}
}

.app--desktop .tab {
	&__list {
		&__item {
			&:hover {
				background: mix($color1, $gray, 40%);
				color: mix(darken($gray, 40%), #fff, 0%);
			}
		}
	}
}

.tab {
	border-radius: 0.5rem 0.5rem 0 0;
	overflow: hidden;

	&__list {
		width: 100%;
		margin: 0 1rem;
		color: desaturate(darken($background, 30%), 20%);

		&__item {
			& + & {
				margin-left: 0.5rem;
			}
			display: inline-block;
			vertical-align: bottom;
			cursor: pointer;
			background: $gray;
			color: darken($gray, 40%);

			transition: background 0.3s $ease1, color 0.3s $ease1;
			padding: 0.5rem 2rem;
			border-radius: 0.5rem 0.5rem 0 0;

			&--active {
				padding: 0.75rem 2rem;
				background: $color1;
				color: #fff;
				pointer-events: none;
			}
			&--inactive {
			}
			&--disabled {
				pointer-events: none;
				opacity: 0.2;
			}

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.icon {
				margin-right: 0.25rem;
			}
		}
	}

	&__section {
		border-radius: 0.5rem 0.5rem 0 0;
		border: 1px solid $gray;
		padding: 1rem 1rem;

		&__item {
			overflow: hidden;
			transition: opacity 0.3s $ease1;

			&--active {
				opacity: 1;
				height: auto;
				z-index: 1;
			}
			&--inactive {
				pointer-events: none;
				z-index: 0;
				opacity: 0;
				height: 0;
			}
		}
	}
}
