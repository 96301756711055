.username {
	margin: 2rem 0;

	&--not-found {
		h1 b {
			color: $secondary;
		}
	}

	&__go-back {
		margin-top: 2rem;
		text-decoration: underline;
		cursor: pointer;
	}

	&__head {
		&__image,
		&__info {
			display: inline-block;
			vertical-align: middle;
		}

		&__info {
			margin-left: 1rem;

			h2.username__head__info__nickname {
				margin: 0;
				color: $color1;
			}

			&__username {
				color: $secondary;
				font-weight: $boldWeight;
			}
		}

		.user-image {
			width: 10rem;
			height: 10rem;
		}
	}

	.ads-container {
		margin: 0rem auto 2rem;
		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}

	&__bottom-ads {
		margin: 2rem 0;
	}
}
