.app__aside {
	position: fixed;
	top: 0;
	right: 0;

	width: 100%;
	height: 100%;
	z-index: 200;

	&__back,
	&__content {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
	}

	&__back {
		width: 100%;
		background: rgba(#000, 0.3);
		transition: background ease-out;
	}
	&__content {
		width: 80%;
		background: $color3;
		background: linear-gradient(135deg, $color3 0%, $color1 120%);
		padding: 4rem 0rem 1rem;
		transition: transform $ease1;

		&__close-icon svg {
			position: absolute;
			top: 1rem;
			left: 1rem;
			fill: #fff;
			width: 2rem;
			height: 2rem;
		}
	}

	&__socials {
		position: absolute;
		bottom: 1rem;
		right: 0;
		width: 100%;
		text-align: center;

		> a {
			display: inline-block;
			margin: 0 0.5rem;

			svg {
				fill: #fff;
				width: 2rem;
				height: 2rem;
			}
		}
	}

	&--open {
		pointer-events: all;
		.app__aside {
			&__back {
				transition-delay: 0s;
				transition-duration: 0.3s;
				background: rgba(#000, 0.3);
			}

			&__content {
				transition-delay: 0.05s;
				transition-duration: 0.25s;
				transform: translateX(0%);
				box-shadow: -0.2rem 0 2rem 0.2rem rgba(#000, 0.3);
			}
		}
	}

	&--close {
		pointer-events: none;
		.app__aside {
			&__back {
				transition-delay: 0.1s;
				transition-duration: 0.2s;
				background: rgba(#000, 0);
			}
			&__content {
				transition-delay: 0s;
				transition-duration: 0.3s;
				transform: translateX(100%);
			}
		}
	}
}
