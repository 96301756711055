.app--mobile .dashboard__profile__meta .ads-container {
	position: initial;
	top: 0;
	right: 0;
	width: auto;
	height: auto;
	margin: 1rem 0;
}

.dashboard__profile__meta {
	position: relative;
	margin-top: 2rem;

	line-height: 8rem;

	&__image {
		position: relative;
		display: inline-block;
		margin-right: 2rem;
		width: 8rem;
		height: 8rem;

		.user-image {
			width: 100%;
			height: 100%;
		}

		&__delete {
			position: absolute;
			bottom: 0%;
			left: 0%;
			transform: translate(50%, 0%);
			background: $secondary;
			width: 2.4rem;
			height: 2.4rem;
			line-height: 2.4rem;
			text-align: center;
			border-radius: 50%;
			opacity: 1;
			cursor: pointer;

			&:hover {
				background: lighten($secondary, 5%);
			}
			svg {
				width: 1.618rem;
				height: 1.618rem;
				display: inline-block;
				vertical-align: middle;
				transform: translateY(-5%);
				fill: #fff;
			}
		}
	}

	&__info {
		line-height: initial;
		display: inline-block;
		vertical-align: middle;
		&__username {
			margin-bottom: 0.5rem;

			&__link:hover {
				color: $secondary;
			}
		}
	}

	.ads-container {
		position: absolute;
		top: 1rem;
		right: 0;
		width: 600px;
		height: 10vh;

		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}
}

.dashboard .tab {
	margin: 2rem 0;
}

.dashboard {
	.app__content {
		> .ads-container {
			margin: 2rem auto 2rem;
			.ads--horizontal {
				max-width: 100%;
				min-height: 10vh;
			}
		}
	}
}
@import './dashboard-transactions.scss';
@import './dashboard-avatars.scss';
@import './dashboard-user-settings.scss';
@import './dashboard-paginator.scss';
