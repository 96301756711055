@keyframes bg-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.app {
	.server-down {
		height: 100vh;
		display: flex;
		line-height: 2;
		background: $color1;
		color: #fff;
		background: linear-gradient(120deg, $color1 0%, $color2 100%);
		background-size: 400% 400%;
		animation: bg-animation 10s ease infinite;
		align-items: center;
		justify-content: center;

		&__wrapper {
		}

		&__container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5vh;
		}

		&__loading {
			text-align: center;
		}

		&__logo {
			max-width: 100%;
			margin-bottom: 5vh;
		}

		&__title {
			margin: 0;
			> * {
				display: inline-block;
				vertical-align: middle;
			}

			svg {
				margin-right: 1rem;
			}
		}
	}

	&.app--desktop {
		.server-down {
		}
	}
	&.app--mobile {
		.server-down {
			padding: 2rem 1rem 0 1rem;

			&__container {
				flex-direction: column;
				gap: 0;
			}

			&__loading {
				display: none;
			}

			&__title {
				line-height: 1;
				svg {
					display: none;
				}
			}
		}
	}
}
