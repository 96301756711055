@import './modal.scss';
@import './input.scss';
@import './appear.scss';
@import './prompt.scss';
@import './ads.scss';
@import './tab.scss';
@import './loading.scss';
@import './hashtag.scss';
@import './messages.scss';
@import './avatar-preview.scss';
@import './add-to-cart-button.scss';
@import './avatar-paginator.scss';

.user-image {
	border-radius: 50%;
	border: 1px solid rgba(#000, 0.05);
	display: inline-block;
	vertical-align: middle;
	width: 4rem;
	height: 4rem;
	position: relative;
	overflow: hidden;
	user-select: none;

	img {
		width: 100%;
	}

	&::before {
		display: block;
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.user-link {
	font-weight: $boldWeight;
	color: $secondary;

	&:hover {
		color: lighten($secondary, 10%);
	}
}
