.dashboard__avatars {
	&__title {
		margin: 2rem 0 1rem 0;
	}

	&__tab {
		margin: 1rem 0 2rem;

		&__item {
			& + & {
				border-left: 1px solid $gray;
			}

			padding: 0 1rem;
			display: inline-block;
			text-align: center;
			color: $gray;
			cursor: pointer;

			.icon {
				display: block;
				margin: 0 auto 0.1rem;

				width: 1.4rem;
				height: 1.4rem;
			}

			&--active {
				color: $color1;
			}
		}
	}

	&__list,
	&__liked-list {
		&__avatar {
			position: relative;
			display: inline-block;

			padding: 0.2rem;

			.avatar-preview {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	&__load-more {
		text-align: center;
		margin: 1rem 0 2rem;
	}
}

.app--desktop .dashboard__avatars {
	&__list,
	&__liked-list {
		&__avatar {
			width: 16.666%;
		}
	}
}
.app--mobile .dashboard__avatars {
	&__list,
	&__liked-list {
		&__avatar {
			width: 50%;
		}
	}
}
