.login-required-modal {
	max-width: 460px;

	&__title {
		margin: 0 0 1rem;
	}

	&__message {
		margin: 0.25rem 0;
	}

	&__buttons {
		margin-top: 3rem;

		.button + .button {
			margin-left: 1rem;
		}
	}
}
