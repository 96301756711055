.gdpr {
	&__container {
		display: flex;
		gap: 2rem;
		align-items: flex-start;
		margin: 1rem 0 2rem;
	}

	&__form {
		display: inline-flex;
		flex-direction: column;
		border: 1px solid $background;
		padding: 1rem;
		border-radius: 6px;
		gap: 1rem;

		&__select-birth {
		}

		&__select-nationality {
		}
	}

	&__other-actions {
		margin-top: 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}
}

.app--desktop .gdpr {
	padding: 1rem;
}

.app--mobile .gdpr {
	padding: 0 1rem 2rem;

	&__container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
