@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;400;600;700&display=swap');

@import './variables.scss';

$title-font: 'Open Sans', sans-serif;

html {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	line-height: 1.2;
}
html,
body {
	position: fixed;
	height: 100%;
	width: 100%;
	overflow: hidden;
	// overflow: hidden;
	margin: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
	background: #fff;
	color: $base-color;
	height: 100%;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

h1 {
	font-size: 2.2rem;
	margin: 0.5rem 0 1rem;
	font-weight: 300;
}

p + h1 {
	margin-top: 3rem;
}

h1,
h2 {
	letter-spacing: -0.02rem;
	font-family: 'HelveticaNeue';
}

h2 {
	font-size: 1.618rem;
	font-weight: 300;
}

.icon {
	user-select: none;
}

@import './app/index.scss';
@import './auth/index.scss';
@import './components/index.scss';
@import './modals/index.scss';
@import './pages/index.scss';

@import '~@faceyourmanga/fym-editor/src/sass/app.scss';

.fym-editor {
	&__button--secondary {
		background: #323082 !important;
		color: #fff !important;
		border-color: #323082 !important;
	}

	&.fym-editor--desktop {
		.fym-editor__button {
			width: 8.2rem;
		}
	}
}

.afterBlock {
	display: block;
	&:after {
		display: block;
		content: ' ';
		clear: both;
	}
}
