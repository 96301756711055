.app--desktop .dashboard__user-settings {
	padding: 2rem;
}

.dashboard__user-settings {
	&__row {
		& + & {
			margin-top: 2rem;
		}

		.input + .button {
			margin-left: 1rem;
		}
	}

	label {
		// color: desaturate(darken($background, 20%), 20%);
		margin-right: 0.25rem;
	}

	.input {
	}

	&__delete {
		border-top: 1px solid $gray;
		margin-top: 2rem;
		padding-top: 2rem;
		text-align: center;

		.button {
			margin-bottom: 1rem;
		}
	}
}

.dashboard__change-username {
	margin: 2rem 0;

	&__title {
		font-size: 1.2rem;
		color: desaturate(darken($background, 20%), 20%);
	}

	&__message {
		display: block;
		font-style: italic;
		margin-bottom: 1.618rem;
		font-size: 0.9rem;
	}

	&__error {
		margin-top: 1rem;
		color: $secondary;
	}

	&__remove {
		border: 1px solid rgba(224, 161, 0, 0.1);
		background: rgba(224, 161, 0, 0.02);
		color: #e0a100;

		padding: 1rem;
		display: inline-flex;
		margin-top: 1rem;
		flex-direction: column;
		color: $yellow;

		&__title {
			margin-bottom: 0.5rem;
			display: flex;
			align-items: center;
			font-weight: $boldWeight;
			font-size: 0.9rem;

			svg {
				margin-right: 0.25rem;
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		.button {
			align-self: flex-start;
			background: lighten($yellow, 40%);
			color: $yellow;

			&.promise-button {
				&.promise-button--waiting {
					&:before {
						background: darken(lighten($yellow, 40%), 5%);
					}
					&:after {
						background: $yellow;
					}
				}
			}
		}
	}
}

.dashboard__change-password {
	display: inline-block;
	text-align: center;
	color: darken($gray, 20%);
}
