.app__footer {
	color: #fff;
	background: $secondary;

	&__logo {
		height: 1rem;
	}

	.app__content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	dl,
	dd {
		margin: 0;
	}

	dt {
		font-weight: $boldWeight;
		margin-bottom: 0.5rem;
	}
	dd {
		font-size: small;
		& + dd {
			margin-top: 0.25rem;
		}
	}

	&__socials {
		> a {
			display: inline-block;
			margin: 0 0.25rem;

			svg {
				fill: #fff;
				width: 2rem;
				height: 2rem;
			}
		}
	}
}

.app--desktop .app__footer {
	padding: 3rem 0;
}

.app--mobile .app__footer {
	padding: 3rem 1rem;

	.app__content {
		flex-direction: column;
		gap: 2rem;
	}
}
