@import './signin-up.scss';
@import './emails.scss';

.auth-providers {
	margin: 1rem 0;
	text-align: center;
	align-items: center;

	&__bar {
		position: relative;
		margin-bottom: 0.5rem;
		color: #bbb;
		font-size: 0.9rem;

		&:before,
		&:after {
			position: absolute;
			top: 50%;
			width: 45%;
			height: 1px;
			margin-top: -1px;
			background-color: #ddd;
			display: block;
			content: ' ';
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
	}

	&__providers {
		&__item {
			margin: 0.25rem 0;
			display: block;
			width: 100%;
			transition: transform $ease1 0.1s;
			color: #fff !important;
			border-radius: 3px;

			img {
				width: 100%;
			}

			&--facebook.button {
				background: desaturate(#1877f2, 16%);

				&:hover {
					background: lighten(desaturate(#1877f2, 16%), 5%);
				}
				&:active {
					background: darken(desaturate(#1877f2, 10%), 10%);
				}
			}
			&--google.button {
				background: desaturate(#ea4335, 16%);

				&:hover {
					background: lighten(desaturate(#ea4335, 16%), 5%);
				}
				&:active {
					background: darken(desaturate(#ea4335, 10%), 10%);
				}
			}
		}
	}
}

.validation-messages {
	border: 1px solid rgba($red, 0.1);
	border-radius: 6px;
	background: rgba($red, 0.02);
	color: $red;
	padding: 1rem;

	&__label {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		font-size: 0.8rem;

		svg {
			fill: $red;
			width: 1.2rem;
			height: 1.2rem;
		}
	}

	&__list {
		list-style: none;
		margin: 1rem 0 0;
		padding: 0;
		display: inline-flex;
		flex-direction: column;

		&__item {
			font-weight: $boldWeight;
		}
	}
}

.auth-container {
}

.auth-banner {
	width: 660px;
	position: absolute;
	bottom: 0;
	left: 46%;
	text-align: center;

	&__title {
		margin: 0;
		font-weight: 400;
		font-size: 3.6rem;
		line-height: 1;
		margin: 0 0 1rem;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 1.3rem;
		line-height: 1;
		margin: 0 0 5vh;
	}

	&__image {
		display: block;
		width: 100%;
		font-size: 0;
		line-height: 0;
	}
}
