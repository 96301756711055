.gallery {
	padding: 2rem 0;

	.ads-container {
		margin: 0rem auto 2rem;
		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}

	&__filters {
		&__sortBy {
			margin: 0 1rem;

			&__item {
				& + & {
					margin-left: 0.5rem;
				}
				display: inline-block;
				vertical-align: bottom;
				background: $gray;
				color: darken($gray, 40%);
				padding: 0.5rem 2rem;
				border-radius: 0.5rem 0.5rem 0 0;
				align-items: center;
				cursor: pointer;
				transition: background 0.3s $ease1, color 0.3s $ease1;

				&--active {
					padding: 0.75rem 2rem;
					background: $color1;
					color: #fff;
					pointer-events: none;
					font-weight: 600;
				}

				> * {
					display: inline-block;
					vertical-align: middle;
				}

				.icon {
					margin-right: 0.25rem;
				}
			}
		}

		&__search {
			border: 1px solid $gray;
			border-bottom-width: 0;
			border-radius: 0.5rem 0.5rem 0 0;

			&__input {
				margin: 2rem 0 1.5rem;
				text-align: center;
				.input {
					width: 100%;
					max-width: 400px;
				}
			}

			&__hashtags {
				width: 100%;
				overflow: auto;
				padding: 0 2rem;
				padding-bottom: 2rem;
				white-space: nowrap;

				&__hashtag {
					display: inline-block;
					& + & {
						margin-left: 0.5rem;
					}
					cursor: pointer;
				}
			}
		}
	}

	&__content {
		border: 1px solid $gray;
		border-top-width: 0;
		border-radius: 0 0 0.5rem 0.5rem;
		font-size: 0;
		min-height: 30vh;

		&__avatar {
			display: inline-block;
			width: 16.6666%;
			padding: 0.25rem;
		}
	}

	&__load-more {
		margin: 2rem 0;
		text-align: center;
	}
}

.gallery-avatar {
	animation: avatarAppear 0.3s 0.1s linear both;

	.avatar-preview {
		width: 100%;
		height: auto;
	}
}

@keyframes avatarAppear {
	from {
		opacity: 0;
		transform: scale(0.9);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.app--mobile .gallery {
	&__filters {
		&__sortBy {
			margin: 0;
			white-space: nowrap;
			text-align: center;
			font-size: 0.9rem;

			&__item {
				& + .gallery__filters__sortBy__item {
					margin-left: 0.25rem;
				}

				padding: 0.5rem 0.75rem;
			}
		}
		&__search {
			.input {
				width: 80%;
			}
			flex-direction: column;

			&__hashtags {
			}
		}
	}

	&__content {
		&__avatar {
			width: 50%;
		}
	}
}
.app--desktop .gallery {
	&__filters {
		&__sortBy {
			&__item {
				&:hover {
					background: mix($color1, $gray, 40%);
					color: mix(darken($gray, 40%), #fff, 0%);
				}
			}
		}
	}
}
