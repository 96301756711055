.app__nav {
	color: #fff;
	display: flex;
	font-size: 0.9rem;

	&__link {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		text-transform: uppercase;
		font-weight: $veryBoldWeight;

		&__profile {
			align-items: center;
			text-transform: lowercase;
		}

		svg {
			width: 1.1rem;
			height: 1.1rem;
			margin-right: 0.5rem;
		}
	}

	&__auth {
	}

	&__link {
		&.active {
			pointer-events: none;
		}
	}

	&__profile-image.user-image {
		align-self: center;
		width: 1.618rem;
		height: 1.618rem;
		cursor: pointer;
	}
}

.app--mobile .app__nav {
	flex-direction: column;
	margin: 1rem 0;

	&__profile-image.user-image {
		width: 4rem;
		height: 4rem;
	}

	&__link {
		margin: 0.4rem 0;
		width: 100%;
		height: 3rem;
		padding: 0 2rem;
		align-items: center;
		justify-content: flex-start;

		&.active {
			background: rgba(#fff, 0.1);
		}
		&__profile {
			.user-image {
				margin-right: 0.5rem;
			}
			height: auto;
		}
	}
}

.app--desktop .app__nav {
	flex-direction: row;
	align-items: center;
	.app__nav__link + .app__nav__link {
		margin-left: 2rem;
	}

	&__link {
		&.active {
			&:after {
				position: absolute;
				display: block;
				content: ' ';
				width: 100%;
				height: 2px;
				background: rgba(#fff, 0.5);
				bottom: -0.25rem;
				left: 0;
			}
		}
	}
}
