$height: 2.6rem;
$inputBorderRadius: 6px;

// $inputColor: desaturate(darken($background, 50%), 40%);
// $inputBorder: 1px solid darken($background, 5%);
// $inputBackground: darken($background, 1%);
// $labelColor: desaturate(darken($background, 20%), 20%);

$inputColor: #000;
$inputBorder: 1px solid darken($gray, 10%);
$inputBackground: #fff;
$labelColor: darken($gray, 50%);

.button--save {
	background: #323082 !important;
	color: #fff !important;
}
.button--save--stroke {
	color: #323082 !important;
	background: #fff !important;
	border: 1px solid #323082 !important;
}
a.button {
	text-decoration: none !important;
}
a.button--yellow {
	color: $yellow;
}

.button-toggle {
	background: $gray;
	color: darken($gray, 20%);
	border: 1px solid darken($gray, 20%);
	cursor: pointer;
	height: $height / 1.618;
	line-height: $height / 1.618;
	padding: 0 0.5rem;
	border-radius: 8px;

	&--active {
		background: $secondary--light;
		color: $secondary;
		border: 1px solid $secondary;
	}
}

.button {
	display: inline-block;
	position: relative;
	height: $height;
	font-size: 0.9rem;
	// font-weight: $veryBoldWeight;
	font-weight: 700;
	line-height: $height;
	padding: 0 1rem;
	background: $secondary;
	color: #fff;
	border: none;
	overflow: hidden;
	border-radius: $inputBorderRadius;
	font-family: inherit;
	cursor: pointer;
	border: 1px solid transparent;
	transition: all 0.2s $ease1;
	// text-transform: uppercase;
	outline: none;
	user-select: none;
	border-radius: $height;

	&--unrounded {
		border-radius: 0;
	}
	&--padded {
		padding-left: 2rem !important;
		padding-right: 2rem !important;
	}

	&--big {
		height: $height * 1.2;
		line-height: $height * 1.2;
		padding: 0 1.2rem;
		border-radius: $height * 1.2;
	}

	&--small {
		height: $height / 1.4;
		line-height: $height / 1.4;
		padding: 0 #{(1rem / 1.4)};
		border-radius: $height / 1.4;
		font-size: 0.8rem;
	}

	&--shadowed {
		box-shadow: 0.25rem 0.5rem 0.2rem rgba(#000, 0.1);

		&:active {
			box-shadow: 0rem 0rem 0rem rgba(#000, 0);
		}
	}

	&--disabled,
	&:disabled {
		background: lighten(desaturate($secondary--light, 70%), 2%);
		color: lighten(desaturate($secondary, 70%), 2%);
		pointer-events: none;
	}

	&--secondary--stroke {
		border: 1px solid $color1;
		background: #fff;
		color: $color1;

		&.button--disabled {
			opacity: 0.6;
		}
	}
	&--secondary {
		background: $secondary;
		color: #fff;

		&.button--disabled {
			opacity: 0.6;
		}
	}

	&--tertiary {
		background: $color1;
		color: #fff;

		&.button--disabled {
			opacity: 0.6;
		}
	}

	&--gray {
		background: #bbb;
		color: #fff;
	}

	&--red {
		background: rgb(211, 35, 35);
		color: #fff;
	}

	&--green {
		background: $green;
		color: #fff;

		&.button--disabled {
			background-color: $gray;
		}

		&.button.promise-button.promise-button--waiting {
			&:before {
				background: lighten($green, 15%);
			}
			&:after {
				background: $green;
			}
		}
	}

	&--yellow {
		background: lighten($yellow, 40%);
		color: $yellow;

		&.button.promise-button.promise-button--waiting {
			&:before {
				background: lighten($yellow, 35%);
			}
			&:after {
				background: $yellow;
			}
		}
	}

	&.promise-button {
		&.promise-button--waiting {
			&:before,
			&:after {
				position: absolute;
				display: block;
				content: ' ';
				height: 2px;
				left: 0;
				bottom: 0px;
				z-index: 1;
			}
			&:before {
				width: 100%;
				background: darken($secondary--light, 5%);
			}
			&:after {
				width: 10%;
				animation: promiseAnimation 1s infinite cubic-bezier(0.5, 0.1, 0, 1.2);
				background: $secondary;
			}
		}
	}

	> .icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.2rem;
		width: 1rem;
		height: 1rem;
		position: relative;
		top: -1px;
	}
}

.app--desktop .button {
	&:hover {
		transform: scale(1.05);

		&.button--shadowed:not(.button--small) {
			box-shadow: 0rem 0.2rem 0.1rem rgba(#000, 0.1);
		}
	}

	&:active {
		transform: scale(0.98) !important;
		opacity: 0.8;
	}
}

@keyframes promiseAnimation {
	0% {
		width: -10%;
		left: 0%;
	}

	40% {
		width: 60%;
	}

	100% {
		width: 0%;
		left: 100%;
	}
}

.select {
	display: inline-block;
	position: relative;
	height: $height;
	border-radius: $inputBorderRadius;
	font-family: inherit;
	padding: 0 0.5rem;
	border: $inputBorder;
	color: $inputColor;
	background: $inputBackground;
	border-radius: $inputBorderRadius;
	outline: none;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	&--invalid {
		color: desaturate(darken($background, 20%), 20%);
	}
}

.select-birth {
	display: flex;
	gap: 0.5rem;
}

.textarea {
	&__textarea {
		padding: 0.5rem 0.5rem;
		min-height: $height * 1.2;
		min-width: 100%;
		resize: none;
	}
}

.input {
	height: $height;
	&__input {
		padding: 0 0.5rem;
	}
}

.input,
.textarea {
	display: inline-block;
	position: relative;
	border-radius: $inputBorderRadius;

	&__label {
		position: absolute;
		transform-origin: left center;
		height: $height;
		line-height: $height;
		pointer-events: none;
		left: 0.5rem;
		transition: all 0.2s $ease1;
		color: $labelColor;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--minimized {
			transform: translateY(-70%) scale(0.8);
		}
	}

	&__textarea:not(:focus) + &__label--minimized,
	&__input:not(:focus) + &__label--minimized {
		opacity: 0.5;
	}

	&__input,
	&__textarea {
		font-family: inherit;
		height: 100%;
		width: 100%;
		border: $inputBorder;
		color: $inputColor;
		background: $inputBackground;
		outline: none;
		transition: transform 0.2s $ease1, color 0.2s $ease1, background 0.2s $ease1, border-color 0.2s $ease1;
		border-radius: inherit;

		&:active,
		&:focus {
			border-color: darken($background, 20%);
			color: darken($background, 70%);
		}
	}

	&--error {
		.textarea__label,
		.textarea__textarea,
		.input__label,
		.input__input {
			color: $secondary;
		}
		.textarea__textarea,
		.input__input {
			border-color: $secondary;
		}
	}
}

.input.input-search {
	display: inline-block;
	border: $inputBorder;
	white-space: nowrap;

	.input {
		&__input {
			max-width: calc(100% - 1.5rem);
			border: none;
			background: none;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.input-search__icon {
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		padding: 0 0 0 0.5rem;
		cursor: pointer;

		.icon {
			display: inline-block;
			vertical-align: middle;
			fill: darken($background, 20%);
		}
	}

	.input__label {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 2rem);
		left: 2rem;
	}
}

.input-hashtag {
	display: inline-block;
	position: relative;

	&__input {
		display: inline-block;
		vertical-align: middle;
		border-radius: $inputBorderRadius;
		width: 200px;

		.input {
			width: calc(200px - 1.8rem);
			display: inline-block;
			vertical-align: middle;
			border-radius: $inputBorderRadius 0 0 $inputBorderRadius;
		}

		&__add {
			background: $color1;
			line-height: 0;
			height: $height;
			line-height: $height;
			display: inline-block;
			vertical-align: middle;
			padding: 0 0.3rem;
			border-radius: 0 $inputBorderRadius $inputBorderRadius 0;
			cursor: pointer;
			color: #fff;

			&:hover {
				opacity: 0.8;
			}

			svg {
				display: inline-block;
				vertical-align: middle;
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}

	&__message {
		display: inline-block;
		vertical-align: middle;
		white-space: normal;
		width: calc(100% - 200px - 1rem);
		margin-left: 1rem;
		font-size: 0.9rem;
		color: #4a4a4a;
	}

	&__autocomplete {
		&--close {
			display: none;
		}
		position: absolute;
		top: $height;
		width: 200px;
		z-index: 100;
		background: #fff;
		border: 1px solid $background;
		border-radius: 0 4px 4px 0;
		padding: 0.2rem;
		font-size: 0.9rem;
		line-height: 1.4rem;
		box-shadow: 0 10px 10px rgba(#000, 0.1);
	}

	&__hastags {
		margin: 0.25rem 0;
		width: 100%;
		display: inline-block;
		vertical-align: middle;

		&__item {
			display: inline-block;
			vertical-align: middle;
			margin: 0.2rem 0.1rem;
		}
	}
}

.app--mobile .input-hashtag,
.modal--mobile .input-hashtag {
	&__input {
		width: 100%;
		.input {
			width: calc(100% - 1.8rem);
		}
	}

	&__autocomplete {
		width: 100%;
	}
	&__message {
		display: block;
		width: 100%;
		margin: 0.5rem 0 0;
	}
}
