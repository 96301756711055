@keyframes likeAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	70% {
		transform: scale(2);
		opacity: 0.1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.avatar-preview {
	user-select: none;
	font-size: 0;
	line-height: 1;
	position: relative;
	width: 200px;
	height: 200px;
	display: inline-block;
	vertical-align: top;
	will-change: opacity, transform;

	&__image {
		position: relative;

		&--rounded {
			border-radius: 0.5rem 0.5rem 0 0;
			overflow: hidden;
		}

		&--cliccable {
			cursor: pointer;
			will-change: opacity;
			opacity: 1;
			transition: opacity 0.3s $ease1;
		}

		img {
			width: 100%;
		}
		img + img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			pointer-events: none;
		}
	}

	&__name {
		width: 100%;
		// background: $color1;
		padding: 0.5rem 0.5rem 0rem;
		color: #000;
		font-size: 1.1rem;
		font-weight: 400;
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		height: 2.6rem;
		white-space: nowrap;
	}

	&__info {
		position: relative;
		width: 100%;
		background: #fff;
		color: #000;
		padding: 0.3rem 0.3rem;
		font-size: 1rem;
		align-items: center;
		border: 1px solid $gray;
		border-top-width: 0;
		border-radius: 0 0 0.5rem 0.5rem;
	}

	&__update {
		font-size: 1.3rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0.3rem;

		svg {
			fill: #c4c4c4;
		}
	}

	&__owner {
		display: none;
		// background-color: #fff;
		// padding: 0 0.1rem;
		border-radius: 2px;
		font-size: 0.9rem;
		font-weight: $boldWeight;
		// position: absolute;
		// bottom: 0.4rem;
		// left: 0.5rem;
		max-width: 80%;
		text-overflow: ellipsis;
		overflow: hidden;

		a {
			color: inherit;
		}
	}

	&__likes {
		> * {
			display: inline-block;
			vertical-align: middle;
		}

		&__counter {
			margin: 0 0.1rem;
		}

		&--animated {
			svg {
				animation: likeAnimation 0.3s $ease1 both;
			}
		}

		.icon {
			width: 1.3rem;
			height: 1.3rem;
			fill: $secondary;
			stroke: $secondary;
		}

		&--cliccable {
			.icon {
				transform: scale(0.9);
				transition: transform 0.1s $ease1;
			}
			&:hover .icon {
				transform: scale(1.1);
			}
		}
	}
}

.app--desktop .avatar-preview {
	&__image--cliccable {
		&:hover {
			opacity: 0.6;
		}
	}
}
