.app {
	height: 100%;

	&__page--static {
		p {
			line-height: 1.9rem;
		}
	}

	&--desktop {
		.app__content {
			min-width: $appContainerSize;
			width: 80%;
			max-width: $appContainerSize;
			padding: 0 2rem;
		}

		.app__section {
			padding-top: 5.618rem;
			min-height: calc(100% - 5.618rem);
		}
		.app__page {
			padding: 10vh 0;
		}

		.app__page--static {
			line-height: 1.9rem;
			padding: 0 10rem;
			text-align: justify;
		}
	}

	&--mobile {
		.app__section {
			transition: padding-top 0.3s $ease1;
			padding-top: 4rem;
		}
		.app__header--minimized + .app__section {
			padding-top: 3rem;
		}
		.app__content {
			padding: 0 1rem;
		}
		.app__page {
			margin: 5vh 0;
		}
	}

	&__section {
		position: relative;

		> div {
			height: 100%;
		}
	}

	&__content {
		display: block;
		margin: 0 auto;
	}

	.bg {
		background: $gradient-fallback;
		background: $gradient;
		color: #fff;
	}

	&__page {
		h2 {
			margin: 3rem 0 1rem;
		}

		li {
			line-height: 1.618;
		}

		a {
			color: $secondary;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@import './header.scss';
@import './router.scss';
@import './footer.scss';
@import './aside.scss';
@import './nav.scss';
@import './server-down.scss';
