.dashboard__transactions {
	margin: 1rem 0;

	&__legend {
		display: inline-block;
		margin-bottom: 2rem;

		&__item {
			display: inline-block;
			& + & {
				margin-left: 2rem;
			}

			&__bullet {
				border-radius: 50%;
				width: 1rem;
				height: 1rem;
				display: inline-block;
				vertical-align: middle;
				content: ' ';
				border-radius: 50%;
				margin-right: 0.5rem;

				&--created {
					background: #ec3524;
				}
				&--completed {
					background: #15ce95;
				}
				&--pending {
					background: #fec00e;
				}
				&--refunded {
					background: rgb(34, 154, 252);
				}
			}

			i {
				margin-left: 0.5rem;
				color: $gray;
			}
		}
	}

	&__message {
		text-align: center;
		display: inline-block;
		border: 1px solid #15ce95;
		border-radius: 1rem;
		padding: 1rem;
		margin: 0 0 2rem;
	}

	&__head {
		color: #fff;
		background: $color1;
		line-height: 2rem;
		border-radius: 4px 4px 0 0;
		overflow: hidden;

		> div + div {
			border-left: 1px solid rgba(#fff, 0.5);
		}
	}

	&__body {
		border: 1px solid $gray;
		border-top-width: 0;
		border-radius: 0 0 4px 4px;
	}

	&__head,
	.dashboard__transaction__row {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> * {
			flex: 1;
			padding: 0 0.5rem;
		}
		> *:nth-child(1) {
			flex: 1;
		}
		> *:nth-child(2) {
			flex: 3;
		}
		> *:nth-child(3) {
			flex: 3;
		}
		> *:nth-child(4) {
			flex: 3;
		}
		> *:nth-child(5) {
			flex: 1;
		}
		> *:nth-child(6) {
			flex: 1;
		}

		svg {
			width: 2rem;
			height: 2rem;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.dashboard__transaction {
		&:nth-child(2n) {
			background: $background;
		}

		& + .dashboard__transaction {
			border-top: 1px solid desaturate(darken($background, 5%), 20%);
		}

		&--open {
		}
		&__row {
			margin: 0;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			svg {
				cursor: pointer;
			}
		}

		&__status {
			display: inline-block;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;

			&--created {
				background: #ec3524;
			}
			&--completed {
				background: #15ce95;
			}
			&--pending {
				background: #fec00e;
			}
			&--refunded {
				background: $color1;
			}
		}

		&__purchases {
			background: #fff;

			&__meta {
				margin: 0 0 1rem;
				display: flex;
				justify-content: space-between;

				i {
					opacity: 0.6;
					margin-right: 0.5rem;
				}
			}
		}

		&__date {
			font-size: 0.9rem;
		}

		&__products {
			&__items {
				display: flex;
				align-items: center;

				&__image {
					width: 29.6%;
				}
				&__more {
					padding: 0 0.5rem;
					font-style: italic;
				}
			}
		}
	}
}
.app--mobile {
	.dashboard {
		&__transactions {
			&__head {
				display: none;
			}
			&__body {
				border: none;
			}
		}

		&__transaction {
			border: 1px solid desaturate(darken($background, 5%), 20%);

			& + .dashboard__transaction {
				margin-top: 1rem;
			}

			&__row {
				flex-direction: column;
				gap: 0.5rem;
			}

			&__products {
				&__items {
					&__image {
					}
					&__more {
					}
				}
			}

			&__quantity {
				display: none;
			}

			&__purchases {
				padding: 0.5rem;

				&__meta {
					flex-direction: column;
					gap: 1rem;

					&__right {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;
					}
				}
			}

			&__total {
				font-weight: $veryBoldWeight;
			}
		}

		&__purchase {
			width: 50%;
		}
	}
}

.app--desktop {
	.dashboard {
		&__transaction {
			&__purchases {
				padding: 1rem 3rem 3rem;
				&__meta {
					&__right {
						display: flex;
						gap: 0.5rem;
					}
				}
			}
		}

		&__purchase {
			width: 16.6666%;
		}
	}
}

.dashboard__purchase {
	width: 100%;
	display: inline-block;

	&__content {
		margin: 0.25rem;
		text-align: center;
	}

	&__image {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 0.5rem;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			display: block;
			font-size: 0;
		}

		&__size {
			width: 100%;
			color: #fff;
			padding: 0.25rem 0;
			text-transform: uppercase;
		}
	}

	&__resolution {
		display: block;
		margin: 0.5rem 0;
	}
}
