.auth-emails {
	&__form {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 400px;
		.input {
			display: block;
			clear: both;
		}
		> * + * {
			margin-top: 1.618rem;
		}
	}

	&__form-errors {
		margin-top: 1.618rem;
	}
}

.auth-emails__password-reset,
.auth-emails__confirm-email {
	&__success {
		color: $green;
	}
}

.app--desktop {
	.auth-emails__password-reset,
	.auth-emails__confirm-email {
	}
}

.app--mobile {
	.auth-emails__password-reset,
	.auth-emails__confirm-email {
		min-height: 45vh;
	}
}
