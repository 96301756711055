@keyframes waitingAnimation {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 500% 0%;
	}
	100% {
		background-position: 0% 0%;
	}
}

.waiting {
	background: linear-gradient(90deg, $background, darken($background, 5%), $background);
	background-size: 500% 100%;
}

.app--mobile .avatar {
	&__container {
		&__image {
			width: 100%;
		}
		&__message {
			width: 100%;
			margin: 1rem 0 0 0;
			padding: 0;
			height: 0;
			line-height: 1;
			&__message {
				height: auto;
				line-height: 1.618;

				> div {
					display: block;
					line-break: anywhere;
				}
			}
		}
	}

	&__meta {
		position: relative;

		&__left {
			width: 100%;

			&__author {
				margin-right: 0.5rem;
			}
		}

		&__right {
			width: 100%;
			position: absolute;
			top: 0.2rem;
			right: 0;

			&__like,
			&__share {
				margin-left: 0.2rem;
				font-size: 0;
			}

			&__button {
				padding: 0.25rem;

				svg,
				img {
					width: 1.4rem;
					height: 1.4rem;
				}
			}
		}

		&__cart {
			width: 100%;
			text-align: center;
			margin: 2rem 0 0 0;
		}
	}
}

.avatar {
	padding: 2rem 0;

	.ads-container {
		margin: 0rem auto 2rem;
		.ads--horizontal {
			max-width: 100%;
			min-height: 10vh;
		}
	}

	&__title {
		display: block;
		min-width: 10rem;
		content: ' ';
		font-size: 2.2rem;
		height: 3rem;
		line-height: 3rem;
		margin: 1rem 0rem;
	}

	&__container {
		&__image {
			display: inline-block;
			vertical-align: top;
			user-select: none;
			font-size: 0;
			width: 36%;
			background: darken($background, 3%);
			position: relative;

			img {
				width: 100%;
			}
		}

		&__message {
			display: inline-block;
			width: 64%;
			height: 372px;
			padding: 0 0 0 1rem;

			&__message {
				text-align: center;
				font-size: 1.618rem;
				padding: 0 2rem;
				user-select: none;
				border-radius: 0.5rem;
				border: 2px solid $gray;
				height: 256px;
				line-height: 256px;
				font-weight: 400;

				> div {
					display: inline-block;
					vertical-align: middle;
					line-height: 1.618;
				}
			}
		}

		.ads-container {
			display: inline-block;
			width: 100%;
			margin: 20px 0 0;

			.ads--horizontal {
				max-width: 100%;
				height: 50px;
			}
		}
	}
}

.avatar__owner,
.avatar__relateds {
	margin: 4rem 0 2rem;

	&__title {
		margin: 0 0rem 1rem;
		font-size: 1.8rem;
	}
}

.app--mobile {
	.avatar__owner,
	.avatar__relateds {
		margin: 5vh 0;

		&__title {
			margin: 0 0 0.5rem 0;
			font-size: 1.4rem;
		}
	}
}

.avatar__owner {
	&__link {
		display: inline-block;
		margin-left: 1rem;
		transition: color 0.1s $ease1;
		&:hover {
			color: $secondary;
		}
		.user-image {
			width: 2rem;
			height: 2rem;
			margin-right: 0.25rem;
		}
	}
}

.avatar__bottom__ads {
	margin: 2rem 0;
}

.avatar__meta {
	margin-top: 1.618rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid $gray;

	> * {
		display: inline-block;
		width: 50%;
		vertical-align: top;
	}
	&__left {
		text-align: left;

		&__author,
		&__like {
			display: inline-block;
		}

		&__author {
			font-size: 1.2rem;
			margin-right: 4rem;
			font-weight: 400;
			position: relative;
			z-index: 1;

			.user-image {
				width: 2.4rem;
				height: 2.4rem;
				margin-right: 0.5rem;
			}

			&__username {
				max-width: 32vw;
				text-overflow: ellipsis;
				overflow: hidden;
				display: inline-block;
				vertical-align: middle;
			}
		}

		&__like {
			font-size: 1.2rem;
			font-weight: 400;
			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.icon {
				fill: $secondary;
				stroke: $secondary;
				margin-right: 0.25rem;
			}
		}

		&__hashtags {
			margin-top: 1rem;
			.hashtag {
				display: inline-block;
				margin: 0.125rem;
			}
		}
	}

	&__right {
		text-align: right;

		&__like,
		&__cart,
		&__share {
			display: inline-block;
			vertical-align: middle;
			margin-left: 2rem;
		}

		.avatar__container__actions__likes--liked svg {
			fill: $secondary;
		}

		&__button {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0.25rem;
			border: 1px solid #000;
			border-radius: 50%;
			padding: 0.4rem;
			line-height: 0;
			font-size: 0;
			line-height: 0;
			transition: transform 0.1s $ease1;

			&:hover {
				transform: scale(1.1);
			}

			svg,
			img {
				width: 1.618rem;
				height: 1.618rem;
			}
			cursor: pointer;
		}
	}
}
