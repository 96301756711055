.cart {
	position: relative;
	border-radius: 4px;
	overflow: hidden;

	&__title {
		margin: 3rem 0 1rem;
	}

	&__free-purchase {
		position: absolute;
		right: 0;
		top: 0;
		font-weight: bold;
		&__count {
			color: $green;
		}
		&__small {
			font-weight: 100;
			font-size: 0.9rem;
		}
	}

	&__head {
		background: $gradient;
		color: #fff;
		border-radius: 8px 8px 0 0;
	}

	&__body {
		border: 1px solid darken($background, 5%);
		border-radius: 8px;

		&__item {
			padding: 0 1rem;
			&:not(:last-child) {
				border-bottom: 1px solid darken($background, 5%);
			}
		}
	}

	&__button-invoice {
		margin: 2rem 0;
		display: block;
		text-align: right;

		.button-toggle--active {
			background: #fff;
			color: #1d1d1d;
			border-color: #1d1d1d;
			font-size: 0.8rem;
		}
	}

	&__billingInfo {
		margin: 2rem 0;

		h2.cart__billingInfo__title {
			margin: 0 0 1rem;
		}

		&__subtitle {
			font-size: 0.9rem;
			margin: 0 0 1rem;
		}

		&__data {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 2rem 1rem;
		}
	}

	&__pay {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 1rem;

		&__prices {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0.25rem 1rem;
			font-size: 1.2rem;
			margin: 0 0.5rem;

			&__small {
				font-size: 0.9rem;
			}
		}
	}
}

.cartItem {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	&__col1 {
		display: flex;
		align-items: center;
		gap: 1rem;
		flex-grow: 2;
	}
	&__col2 {
		flex-grow: 1;
		text-align: right;
	}
	&__col3 {
		text-align: right;
		flex-grow: 1;
	}

	&__delete {
		font-size: 0;
		line-height: 0;
		cursor: pointer;
		opacity: 1;

		&:hover {
			opacity: 0.4;
		}

		.icon {
			fill: $color1;
			width: 2rem;
			height: 2rem;
		}
	}

	&__image {
		font-size: 0;
		width: 6rem;

		img {
			width: 100%;
		}
	}

	&__resolution {
		small {
			// color: darken($gray, 20%);
			color: #141414;
			display: block;
			margin-bottom: 0.5rem;
		}
	}

	&__price {
		font-weight: $veryBoldWeight;

		&--offer {
			color: $third;
		}
	}
}

.app--desktop {
	.cart {
		&__body {
			border-width: 0 1px 1px;
			border-radius: 0 0 8px 8px;
		}
	}
}
.app--mobile {
	.cart {
		&__head {
			display: none;
		}
		&__button-invoice {
			text-align: center;
		}

		&__pay {
			position: relative;
			align-items: center;
		}

		&__billingInfo {
			&__data {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
			}
		}

		.cartItem {
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&__col1 {
				width: 100%;
				flex-direction: column;
				position: relative;
			}
			&__delete {
				position: absolute;
				top: 0;
				left: 0;
			}
			&__resolution {
				grid-column: span 2;
				text-align: center;
			}

			&__image {
				width: 60%;
			}
		}
	}
}
