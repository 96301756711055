.save-avatar-modal {
	&__title {
		margin-top: 0;
	}

	&__container,
	&__ads {
		display: inline-block;
		vertical-align: middle;
	}

	&__container {
		margin-left: 2rem;
	}
	&__ads {
		.ads-container {
			width: 336px;
			height: 280px;

			.ads {
				width: 100%;
				height: 100%;
			}
		}
	}

	.input-hashtag__message {
		max-width: 200px;
	}

	&__row {
		& + & {
			margin-top: 1rem;
		}

		&--center {
			text-align: center;
		}

		.addToCartButton {
			margin-top: 0.5rem;
		}

		a {
			color: $secondary;
		}
	}

	&__error {
		color: $red;
	}
}

.modal--mobile .save-avatar-modal {
	max-height: 80vh;
	overflow: auto;

	&__title {
		margin-top: 2rem;
	}

	&__container,
	&__ads {
		display: block;
	}

	&__container {
		margin-left: 0;
	}

	&__ads {
		margin-top: 1rem;
		.ads-container {
			width: 100%;
			height: 120px;
		}
	}

	&__row {
		> .input {
			width: 100%;
		}
	}

	.input-hashtag__message {
		max-width: 100%;
	}
}
