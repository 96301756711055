.addToCartButton {
	position: relative;
	display: inline-block;

	&__button {
		background: $third;
		color: #fff;
		font-weight: $boldWeight;
		display: inline-block;
		padding: 0.25rem 1rem 0.25rem 0.75rem;
		border-radius: 3px;
		cursor: pointer;
		transition: background 0.3s $ease1;
		border: 1px solid darken($third, 5%);
		font-size: 1rem;
		font-family: inherit;

		&:not(.addToCartButton__button--open):hover {
			background: lighten($third, 10%);
			border: 1px solid darken($third, 0%);
			box-shadow: 0 0 10px rgba($third, 0.2);
		}

		&:active,
		&--open {
			background: desaturate(darken($third, 10%), 20%);
			border: 1px solid desaturate(darken($third, 15%), 20%);
			color: rgba(#fff, 0.9);
		}

		> *,
		svg {
			display: inline-block;
			vertical-align: middle;
		}

		&__icon {
			svg {
				width: 1.618rem;
				height: 1.618rem;
			}
		}

		&__label {
			margin-left: 0.5rem;
			line-height: 1.618rem;
		}
	}

	&__prices {
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: 2px;
		white-space: nowrap;
		border: 1px solid $gray;
		border-radius: 4px;
		background-color: #fff;
		text-align: left;
		z-index: 1;

		&__price {
			padding: 0.6rem;
			border-bottom: 1px solid $gray;
			font-size: 0.9rem;
			cursor: pointer;

			&:hover {
				background: #f0f0f0;
			}
		}
	}
}

.app--mobile .addToCartButton {
	&__prices {
		right: 50%;
		transform: translateX(50%);
	}
}
