@keyframes MessageAnimation {
	from {
		opacity: 0.8;
		transform: translate(0%, 50%) scale(0.8);
	}
	to {
		opacity: 1;
		transform: translate(0%, 0%) scale(1);
	}
}

.app--desktop .messages {
	margin: 6.618rem 0;
	top: 0;
}

.app--mobile .messages {
	bottom: 0;
}

.messages {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	list-style: none;
	margin: 0.25rem;
	width: 100%;
	padding: 0;
	z-index: 1000000;
	display: flex;
	flex-direction: column;
	align-items: center;
	pointer-events: none;

	&__message {
		position: relative;
		border-radius: 4px;
		// background: lighten($secondary--light, 2%);
		// border: 1px solid darken($secondary--light, 2%);
		// color: $secondary;
		background: #470ceb;
		border: 5px #fff;
		color: #fff;
		font-weight: bold;
		padding: 1rem;
		margin: 0.5rem;
		transform-origin: center center;
		animation: MessageAnimation 0.1s ease-out both;
		pointer-events: auto;

		// &:after,
		// &:before {
		// 	position: absolute;
		// 	top: 100%;
		// 	left: 0.3rem;
		// 	border-left: 10px solid;
		// 	border-bottom: 8px solid transparent;
		// 	border-right: 8px solid transparent;
		// 	display: block;
		// 	content: ' ';
		// }

		// &:after {
		// 	border-left-color: lighten($secondary--light, 2%);
		// }
		// &:before {
		// 	border-left-color: darken($secondary--light, 2%);
		// 	transform: scale(1.2) translate(1px, 1px);
		// }
		// &:after,
		// &:before {
		// 	position: absolute;
		// 	top: 100%;
		// 	left: 50%;
		// 	border-left: 8px solid transparent;
		// 	border-top: 8px solid;
		// 	border-right: 8px solid transparent;
		// 	display: block;
		// 	content: ' ';
		// }

		// &:after {
		// 	border-top-color: lighten($secondary--light, 2%);
		// 	transform: scale(1) translate(-50%, 0);
		// }
		// &:before {
		// 	border-top-color: darken($secondary--light, 2%);
		// 	transform: scale(1.2) translate(-42%, 1px);
		// }

		&--error {
			color: rgba(#faa, 1);
			box-shadow: 0 1rem 2rem rgba(#f00, 0.2);
			background: rgba(#f00, 0.1);
			border: 1px solid rgba(#f00, 0.1);
		}

		&--success {
			color: rgba(#afa, 1);
			background: rgba(#0f0, 0.1);
			box-shadow: 0 1rem 2rem rgba(#0f0, 0.2);
			border: 1px solid rgba(#0f0, 0.1);
		}
	}
}
