////// START HOME SLIDE

.app--mobile .home {
	h1 {
		font-size: 1.8rem;
	}
}

.home-adv {
	margin: 1rem auto;
	.ads--horizontal {
		max-width: 100%;
		min-height: 10vh;
	}
}

.home {
	h1 {
		margin: 0;
		line-height: 1;
	}

	.appear {
		transform-origin: center center;

		&--visible {
			opacity: 1;
			transform: none;
		}

		&--hidden {
			opacity: 0;
			transform: none;
		}
	}
}

.home-community {
	margin: 5vh 0;

	> * {
		display: inline-block;
		vertical-align: middle;
	}

	&__image {
		width: 40%;
		img {
			width: 100%;
		}
	}

	&__message {
		padding: 0 2rem;
		width: 60%;
		color: #1f1f1f;

		h1 {
			font-size: 2.9rem;
		}
		p {
			line-height: 1.8rem;
			font-size: 1.3rem;
		}
	}
}

.app--mobile {
	.home-community {
		> * {
			display: block;
			width: 100%;
		}

		&__image {
			margin-bottom: 1rem;
		}
	}
}

.home-get-started {
	// background: $secondary;
	// padding: 1rem 0;
	background: #f53369;
	padding: 2.4rem 3rem;
	color: #fff;

	&__message,
	&__button {
		display: inline-block;
		vertical-align: middle;
		width: 50%;
	}

	&__message {
		text-align: left;
		h2 {
			margin: 0;
			font-size: 3.6rem;
			margin-bottom: 0.3rem;
			font-weight: 300;
		}
		div {
			font-size: 1.3rem;
		}
	}

	&__button {
		text-align: center;

		.button {
			margin-bottom: 0.5rem;
			background-color: #fff;
			opacity: 1;
			color: #f53369;
		}
		small {
			margin-top: 0.5rem;
		}
	}
}

.app--mobile .home-get-started {
	&__message,
	&__button {
		display: block;
		width: 100%;
		text-align: center;
	}

	&__message {
		margin-bottom: 1rem;
	}
}

.home-slide {
	overflow: hidden;

	&__caption {
		flex: 1;
		h1 {
			font-weight: 100;
			margin: 0 0 1rem;
			line-height: 1.2;
		}
	}
	&__image {
		flex: 1.2;
		font-size: 0;
	}
}

.app--mobile .home-slide {
	padding: 0 1rem;
	display: flex;
	flex-direction: column;

	&__caption {
		position: relative;
		z-index: 2;
		padding: 2rem 2rem 0;

		h1 {
			font-size: 1.8rem;
		}
	}

	&__image {
		margin-top: -1rem;
		img {
			width: 100%;
		}
	}
}

@keyframes home-slide-animation {
	from {
		height: 40vh;
	}
	to {
		height: 50vh;
	}
}

.app--desktop .home-slide {
	animation: home-slide-animation 0.5s $ease1 both;
	height: 50vh;
	display: flex;
	align-items: center;

	&__caption {
		h1 {
			font-size: 2.6rem;
		}
	}

	&__image {
		padding-top: 5vh;
		img {
			height: 45vh;
		}
	}
}

////// END HOME SLIDE

////// START HOME GALLERY
@keyframes avatar-gallery-enter {
	from {
		opacity: 0;
		transform: scale(0.9);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.home-gallery {
	margin: 5vh 0rem;
	width: 100%;

	&__message {
		text-align: center;
		margin-bottom: 2rem;
	}

	&__avatars {
		position: relative;
		width: 750px;
		display: inline-block;
		vertical-align: top;

		&__list {
			font-size: 0;

			&__item {
				width: 150px;
				height: 150px;
				border: 2px solid #fff;
				display: inline-block;
				vertical-align: top;

				.avatar-preview {
					@include enterAnim(avatar-gallery-enter, 500ms, 60ms, 12, $ease1);
					width: 100%;
					height: auto;
				}
			}
		}

		&__arrows {
			float: left;
			pointer-events: none;

			&__arrow {
				cursor: pointer;
				display: inline-block;
				pointer-events: all;

				svg {
					width: 2rem;
					height: 2rem;
				}

				&--inactive {
					pointer-events: none;
					opacity: 0.2;
				}
			}
			&__arrow--left {
				left: -2.2rem;
			}
			&__arrow--right {
				right: -2.2rem;
			}
		}

		&__link {
			float: right;
			line-height: 2rem;
			font-weight: bold;
		}

		.avatar-preview {
			width: 100%;
		}
	}

	&__container {
		text-align: center;
	}
	&__ads {
		margin-left: 2rem;
		display: inline-block;
		vertical-align: top;
		width: 250px;

		.ads--square {
			width: 216px;
			height: 216px;
			margin-bottom: 14px;
		}
	}
}

.app--desktop .home-gallery__ads .ads--horizontal {
	width: 216px;
	height: 440px;
	margin-bottom: 14px;
}

.app--mobile .home-gallery {
	margin: 5vh 0rem;

	&__message {
		text-align: center;
		margin-bottom: 2rem;
	}

	&__avatars {
		text-align: left;
		width: 100%;

		overflow: hidden;

		&__list {
			height: calc((50vw - 1rem) * 3);
		}
		&__list__item {
			width: 50%;
			height: auto;
		}

		&__footer {
			margin: 1rem 0;
		}
	}
	&__ads {
		width: 100%;
		padding: 0;
		margin-left: 0;

		> * {
			display: inline-block;
		}

		.ads-container {
			width: 100%;
			margin: 2rem 0 0;
		}

		.ads {
			width: 100%;
		}
	}
}

////// END HOME GALLERY

////// START HOME MICK JAGGER
.home-mick {
	background-color: #ffdb38;

	.app__content {
		> * {
			display: inline-block;
			vertical-align: middle;
		}
	}
	color: #1f1f1f;
	h1 {
		margin-bottom: 1rem;
		font-size: 2.9rem;
	}

	&__image {
		position: relative;
		font-size: 0;
		line-height: 0;
		width: 36%;
		z-index: 2;

		img {
			width: 100%;
		}

		&:after {
			display: block;
			content: ' ';
			background-color: #fcfdfc;
			width: 1000px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 50%;
			z-index: -1;
		}
	}
	&__message {
		font-size: 1.3rem;
		line-height: 1.8rem;
		color: #1f1f1f;
		margin-left: 2rem;

		&__button {
			.button {
				margin-top: 2rem;
				background-color: #fff;
				color: #f53369;
			}
		}
	}
}

.app--mobile .home-mick {
	&__image {
		width: 100%;
	}
	h1 {
		margin-top: 2rem;
	}
	&__message {
		margin: 0;
		width: 100%;
		text-align: center;
	}
	&__message__button {
		text-align: center;
		margin-bottom: 1rem;
	}
}

.app--mobile .home-pricings {
	.app__content {
		flex-direction: column-reverse;
	}

	&__message {
		margin-top: 5vh;
	}
}

////// END HOME PRICINGS

////// START HOME AVATARS CREATED
.home-avatars-created {
	margin: -5vh 0 -4vh 0;
	text-align: center;

	&__numbers {
		$size: 2rem;
		font-size: $size;
		font-weight: $boldWeight;
		display: inline-block;
		margin-top: 1rem;

		&__separator {
			display: inline-block;
			margin: 0 0.25rem;
		}
		&__number {
			display: inline-block;
			margin: 0 0.125rem;
			width: $size * 1.2;
			height: $size * 1.5;
			line-height: $size * 1.5;
			text-align: center;
			background: linear-gradient(
				180deg,
				rgba(60, 60, 60, 1) 0%,
				rgba(60, 60, 60, 1) 50%,
				rgba(20, 20, 20, 1) 50%,
				rgba(20, 20, 20, 1) 100%
			);
			color: #fff;
			border-radius: 10px;
		}
	}

	&__counter {
		display: inline-block;
		vertical-align: middle;
		padding-right: 5rem;
		h1 {
			font-size: 2.6rem;
		}
	}
	&__image {
		font-size: 0;
		display: inline-block;
		vertical-align: middle;
		width: 36%;
		img {
			width: 100%;
		}
	}
}

.app--mobile .home-avatars-created {
	margin: 2rem 0;

	&__counter {
		width: 100%;
		padding: 0;
	}

	&__image {
		width: 60%;
		margin-top: 2rem;
	}

	&__numbers {
		margin-top: 1rem;

		$size: 1.8rem;
		font-size: $size;

		&__separator {
			margin: 0 0.2rem;
		}
		&__number {
			width: $size * 1.2;
			height: $size * 1.5;
			line-height: $size * 1.5;
			margin: 0 0.1rem;
		}
	}
}

////// START HOME AVATARS CREATED

.home-avatars-pricings {
	&__message {
		background-color: $background;
		text-align: center;
		padding: 7vh 0 7rem;

		h1 {
			margin-bottom: 1rem;
			font-size: 2.9rem;
		}
		div {
			font-size: 1.3rem;
			line-height: 1.8rem;
			color: #1f1f1f;
		}
	}

	&__prices {
		position: relative;
		margin-top: -5rem;

		&__price {
			display: inline-block;
			width: 33%;
			padding: 1rem;
		}
	}

	&__buttons {
		margin: 3rem 0 4rem;
		text-align: center;
		font-size: 1.3rem;

		.button {
			margin-bottom: 0.5rem;
		}

		a {
			color: $secondary;
			cursor: pointer;
		}
	}

	.home-price {
		width: 100%;
		// padding: 1rem 2rem;
		padding: 2rem 2rem;
		color: #fff;
		text-align: center;
		border-radius: 0.5rem;

		&__name {
			background: #fff;
			display: inline-block;
			font-weight: 400;
			font-size: 1.7rem;
			padding: 0.1rem 3rem;
			line-height: 1.2;
			border-radius: 1rem;
			font-family: 'Helvetica', sans-serif;
		}

		&__price {
			font-size: 2rem;

			> * {
				display: inline-block;
				vertical-align: top;
			}
			&__currency {
				margin-right: 0.25rem;
			}
			&__int {
				font-size: 6rem;
				font-family: 'Helvetica', sans-serif;
				font-weight: 500;
			}

			&__currency,
			&__decimal {
				position: relative;
				top: 1.2rem;
			}
		}

		&__description {
			text-align: left;
			font-size: 1.2rem;
			&__item {
				border-top: 1px solid #fff;
				padding: 1rem 0;

				&--two {
					height: 6.6rem;
					line-height: 6.6rem;
					padding: 0rem 0;
					> div {
						display: inline-block;
						vertical-align: middle;
						line-height: 1.2;
					}
				}
			}
		}
	}
}

.app--mobile .home-avatars-pricings {
	&__prices__price {
		width: 100%;
	}
}
